import Http from "axios";

const API_URL = process.env.REACT_APP_API_URL;

class Api {
  constructor() {
    Http.defaults.baseURL = API_URL;
    Http.defaults.timeout = 300000;
  }

  async get({ resource = "", responseType = null, params = {} }) {
    const config = {
      responseType,
      params,
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await Http.get(`${API_URL}${resource}`, config);
      return this.successResponse(res);
    } catch (error) {
      return this.errorResponse(error);
    }
  }

  errorResponse(error) {
    switch (error) {
      default:
        console.log(error);
    }
  }

  successResponse(response) {
    return this.response(response);
  }

  response({ data, status, headers }) {
    return {
      body: data,
      status,
      headers,
    };
  }
}

export default new Api();
