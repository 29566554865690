import Api from "../api";


class Search {
    fetchRedirectLink = async (params = {}) =>{
        const {body} = await Api.get({
            params:params
        });
        return body;
    }
}

export default new Search();

