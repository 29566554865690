import React, { useEffect, useCallback, useState } from "react";
import "./App.scss";
import { Search } from "./services/Search";
import Bowser from "bowser";
import Error from "./components/error";
function App() {
  const [hasError, setHasError] = useState(false);
  const [xmlContent, setXmlContent] = useState(null);
  const [templateContent, setTemplateContent] = useState(null);

  const fetchRedirectLink = useCallback((paramsToSend) => {
    Search.fetchRedirectLink(paramsToSend)
      .then((response) => {
        if (response.type === "redirect") {
          redirectToSearchResult(response.data);
        } else if (response.type === "xml") {
          setXmlContent(response.data);
        } else {
          setTemplateContent(response.data);
        }
      })
      .catch((error) => {
        setHasError(true);
        console.trace(error);
      });
  }, []);

  function getDeviceInfo() {
    const result = Bowser.parse(window.navigator.userAgent);
    let browserName = result.browser.name || "Unknown Browser";
    let browserVersion = result.browser.version || "Unknown Version";
    let browserInfo = `${browserName}, ${browserVersion}`;
    let osName = result.os.name || "Unknown OS";
    let osVersion = result.os.version || "";
    let osInfo = `${osName} ${osVersion}`;
    let deviceName = result.platform.type || "Unknown";
    let deviceInfo = `${deviceName}`;
    return {
      browser_info: browserInfo,
      device_info: deviceInfo,
      os_info: osInfo,
    };
  }
  const checkParams = useCallback(() => {
    let deviceInfo = getDeviceInfo();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const keys = urlParams.keys();
    const allRequiredParamsAvailable = urlParams.has("q");
    if (allRequiredParamsAvailable) {
      let paramsToSend = {};
      for (const key of keys) {
        paramsToSend[key] = urlParams.get(key);
      }
      paramsToSend = {
        ...paramsToSend,
        ...deviceInfo,
      };
      fetchRedirectLink(paramsToSend);
    } else {
      setHasError(true);
    }
  }, [fetchRedirectLink]);

  const redirectToSearchResult = (link) => {
    let checkedlink = link.match(/^http[s]?:\/\//) ? link : "http://" + link;
    window.location.replace(checkedlink);
  };

  useEffect(() => {
    checkParams();
  }, [checkParams]);

  return (
    <div>
      {hasError ? (
        <Error />
      ) : xmlContent ? (
        <div>
          <pre>{xmlContent}</pre>
        </div>
      ) : (
        templateContent && (
          <div dangerouslySetInnerHTML={{ __html: templateContent }}></div>
        )
      )}
    </div>
  );
}

export default App;
