import React from "react";
import "./error.scss";
const Error = () => {
  return (
    <main>
      <div>
        <h1 className="title">Oops!</h1>
        <div className="message">
          <h3>404 - PAGE NOT FOUND</h3>
          <h4>
            The page you are looking for might have been removed had its name
            changed or is temporarily unavailable
          </h4>
        </div>
      </div>
    </main>
  );
};

export default Error;
